@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import '~datatables.net-dt/css/jquery.dataTables.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

::-webkit-scrollbar{
  width: 0px;
  height: 6px;


}

::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
 
   
}

::-webkit-scrollbar-thumb{
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background: #a5aaad;
}

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  
}

h1,h2,h3,h4,h5,h6.a,ul,li {
  margin: 0;
  padding: 0;
}