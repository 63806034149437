@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

:root {
  --primary-color: red;
  --secounary-color: rgba(88, 88, 88, 0.53);
  --sidebar-link-color-active: #fff;
  --sidebar-link-background-active: #000000;
  --sidebar-link-icon-active: #000000;
  --datatable-border-bottom: #000000;
  --sidebar-background: #000;
}

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--secounary-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}
div:hover .view-icon {
  display: flex; /* Show icon on hover */
}
body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 13px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
}

.charts__left {
  padding: 25px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  border: 1px solid #f5f5f5;
}

.charts__left:hover,
.charts__right:hover {
  box-shadow: 5px 5px 13px #bcb9b9, -5px -5px 13px #ffffff;
  transition: 0.8s;
}

.charts__left__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__left__title>div>h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__left__title>div>p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__left__title>i {
  color: #ffffff;
  font-size: 20px;
  background: #ffc100;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right {
  padding: 25px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
  border: 1px solid #f5f5f5;
}

.charts__right__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.charts__right__title>div>h1 {
  font-size: 24px;
  color: #2e4a66;
  margin-bottom: 5px;
}

.charts__right__title>div>p {
  font-size: 14px;
  font-weight: 700;
  color: #a5aaad;
}

.charts__right__title>i {
  color: #ffffff;
  font-size: 20px;
  background: #39447a;
  border-radius: 200px 0px 200px 200px;
  -moz-border-radius: 200px 0px 200px 200px;
  -webkit-border-radius: 200px 0px 200px 200px;
  border: 0px solid #000000;
  padding: 15px;
}

.charts__right__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

.card1 {
  background: #d1ecf1;
  color: #35a4ba;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card2 {
  background: #d2f9ee;
  color: #38e1b0;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card3 {
  background: #d6d8d9;
  color: #3a3e41;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

.card4 {
  background: #fddcdf;
  color: #f65a6f;
  text-align: center;
  padding: 25px;
  border-radius: 5px;
  font-size: 14px;
}

/* dashboard */

.orderdiv{
    width: 30%;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 20px ;
    

}
/*  SIDEBAR STARTS HERE  */

.Viewall-buttons{
 
    display: flex;
    align-items: flex-end; /* Aligns items to the end vertically */
    justify-content: flex-end; /* Optional: Aligns items to the end horizontally */
  
}
#sidebar {
  background: var(--sidebar-background);
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}

.sidebar__img {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 30px;
}

.sidebar__title>div>img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title>div>h1 {
  font-size: 18px;
  display: inline;
  color: #ffffff;
}

.sidebar__title>i {
  font-size: 18px;
  display: none;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sidebar__link>a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__routes {
  transition: 1s;
  background-color: transparent;
}

.sidebar__routes:hover,
.sidebar__routes.active {
  background-image: linear-gradient(to right,
      transparent,
      var(--sidebar-link-background-active),
      transparent) !important;
  color: var(--sidebar-link-color-active);
  -webkit-transition: background-image 500ms linear;
  -ms-transition: background-image 500ms linear;
  transition: background-image 500ms linear;
}

.sidebar__routes:hover span,
.sidebar__routes.active span {
  color: #ffffff !important;
  transition: 500ms;
}

.sidebar__routes:hover svg,
.sidebar__routes.active svg {
  color: var(--sidebar-link-icon-active) !important;
  transition: 500ms;
}

.sidebar__routes span,
.sidebar__routes svg {
  color: #9899ac !important;
  transition: 500ms;
  font-size: 13px !important;
}

.sidebar__link>i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout>a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout>i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}

.datatable_action_icons {
  font-size: larger;
  cursor: pointer;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  line-height: 2;
}

.datatable-action-btn {
  border: none;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--primary-color) !important;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #585858;
  background: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: var(--primary-color) !important;
  border: 1px solid transparent;
  background-color: #585858;
  background: transparent;
  border-radius: 5px;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_filter input {
  border: 1px solid transparent;
  border-bottom: 1px solid #ccc;
  border-radius: 3px;
  padding-left: 20px;
}

.dataTables_wrapper .dataTables_filter::before {
  content: "\f002";
  color: #000;
  margin-right: -15px;
  font: normal normal normal 14px/1 FontAwesome;
}

.dataTables_filter input:focus-visible,
.dataTables_wrapper .dataTables_length select:focus-visible {
  outline: none;
}

.dataTables_wrapper .dataTables_length select {
  border: 1px solid transparent;
  margin: auto 10px;
}

table.dataTable.no-footer {
  margin-bottom: 15px;
}

table.dataTable.no-footer {
  border-bottom: 1px solid var(--datatable-border-bottom);
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px); /* Adjust the height of the bounce here */
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 0.9s infinite; /* Duration can be adjusted */
}

@media only screen and (max-width: 855px) {
  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

.offer-flag {
  background: red;
  color: white !important;
  border-radius: 5px;
  padding: 0 5px;
  margin: 2px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
  font-size: 10px;
}

/* .dashboard-card-animate {
  animation: my-animation 5s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 25%);
  background-size: 1000px 100%;
}

@keyframes my-animation {
  from{
    background-position: -1000px 0;
  }
  to{
    background-position: 1000px 0;
  }

} */

.variant-proportion-button-div {
  cursor: pointer;
}

.variant-proportion-button-div-disable {
  opacity: 0.5;
  pointer-events: none;
  cursor: none;
}

.home_parent {
  width: 100%;
  height: 100vh;
  display: table;
  text-align: center;
}

.home_parent>.child {
  display: table-cell;
  vertical-align: middle;
  font-size: 30px;
}

.MuiDialog-paper {
  min-width: 50vw !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  /* z-index: 9999 !important; */
  z-index: 9999;
}

.menu-block {
  padding: 20px 50px;

}

.menu-list {
  border-right: 1px solid #696f76;
}

.menu-list ul li {
  list-style: none;
  padding-top: 20px;
}

.menu-list ul li a {
  color: #696f76;
}

.menu-list ul li a:hover {
  color: #2b95e8;
}

.menu-items {
  padding: 20px 0px;
}

.css-j204z7-MuiFormControlLabel-root {
  justify-content: center !important;
}

.css-vj1n65-MuiGrid-root {
  display: flex;
  justify-content: center;
}

.food-card .food-name p {
  margin-top: 8px;
  font-size: 14px;
  margin-left: -20px;
}

.css-46bh2p-MuiCardContent-root {
  padding: 0px !important;
}

.branchCard{
  padding-left: 10px;
}

.food-rate {
  display: flex;
  align-items: center;
}

.menu-items button {
  padding: 0px;
}

.css-j7qwjs {
  width: 500px;
}

.menu-item-card-lgr {
  display: none;
}

.menu-item-card {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.cart-place {
  padding: 20px 20px;
}

.cart-detail {
  padding: 20px 10px;

}

.cart-amount {
  padding-top: 10px;

}

.cart-food-detail {
  display: flex;
  align-items: center;
}

.cart-amount-detail {
  padding-top: 10px;
  justify-content: center;
}

.cart-food-total-rate p {
  text-align: right;
  padding-right: 5px;
}

.cart-title, .cart-amount-detail {
  border-bottom: 1px solid #ccc;
}

.counter {
  width: 30%;
}

@media (max-width: 900px) {

  .menu-block {
    display: none;
  }

  .cart-place {
    display: none;
  }
  .orderdiv{
    width: 40%;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 20px ;

}

}

@media (max-width: 768px) {

  .menu-item-card {
    display: none;
  }
  .orderdiv{
    width: 50%;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        margin: 20px ;
}

  .menu-item-card-lgr {
    display: block;
  }

  .food-card-lgr-image img {
    max-width: 100%;
    height: 100%;
  }

  .food-card-lgr p {
    font-size: 10px;
    padding-left: 8px;
  }

  .menu-items button {
    width: 0px;
    float: right !important;
  }

  .css-14t4967-MuiButtonBase-root-MuiButton-root {
    line-height: 0;
  }

  .css-11lq3yg-MuiGrid-root {
    align-items: center;
  }

  .css-1o7apob-MuiGrid-root {
    display: flex;
  }

  .css-i4bv87-MuiSvgIcon-root {
    float: right;
    padding: 2px;
  }

  .css-9dhoya-MuiPaper-root-MuiCard-root {
    min-width: 0;
  }

}

.cardImage {
  height: 20vh !important;
  width: 100% !important;
}

.quantity {
  background: black;
  color: white;
  padding: 5px;
}

.cart_addon {
  background: mistyrose;
  padding: 3px;
  margin: 5px;
  font-size: 12px;
}

.category .categoryDiv {
  border: 1px solid #b5b5c3;
  /* padding: 10px; */
  margin: 10px;
  height: 100px;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  width: 95% !important;
}

/* .categoryDiv {
  cursor: pointer;
  width: 95%;
} */

.paymentCardDiv {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  font-size: 2vw;
}

.paymentCardDiv:hover{
  background-color: #aeaeae;
  color: white;
}

.dialButton{
  font-size: 2vw !important;
  margin: 10px !important;
}

.interAmount .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  font-size: 3vw;
}

.currencySymbel{
  display: flex !important;
  text-align: center !important;
}

.partial .MuiInputBase-input{
  font-size: 2vw !important;
}

.radioBtn > label{
  width: 100%;
    margin: 5px;
    padding: 10px;
    background: #f0f0f0;
}

.css-126xj0f{
  /* z-index: 9999 !important; */
  z-index: 9999;
}

.nagative{
  color: red;
}
.positive{
  color: green;
}

.newOrder .css-m51jw0-MuiPaper-root-MuiDialog-paper{
  overflow: hidden !important;
}

.category .selected{
  background-color: #40b931;
  color: #ffff;
  font-weight: bold;
}

.selected{
  background-color: #40b931 !important;
  color: #ffff !important;
  font-weight: bold;
}

.show{
  display: block !important;
}
.hidden{
  display: none !important;
}
.Toastify__toast-container{
  z-index: 99999 !important;
}
.organiztionDetails{
  position: absolute;
  width: 100%;
  display:flex;
  justify-content:center;
  /* bottom: 0; */
}

.organiztionDetails .text{
  color: #9899ac85;
  position:absolute;
  bottom:0
}

.organiztionDetails .text a{
  color: #3699ff
}
.orders-badge{
  display: inline;
    /* align-items: center; */
    margin: 8px;
    padding: 3px 12px;
    border-radius: 0.425rem;
    background: #FEB2B2;
     font-weight:600
    /* color: #E53E3E; */
}
.orders-link:hover{
  color: #3699ff
}
.order-details-title{
  font-weight: 500;
  font-size: 1.16rem;
  color: #99A1B7;
  margin: 0 1rem;
  padding: 0.5rem 0;
  transition: color .2s ease;
}

.order-details-title.active{
  color:#1B84FF;
  background-color: transparent;
  margin: 0 1rem;
  padding: 0.5rem 0;
  font-weight: 500;
  font-size: 1.16rem;
  border-bottom: 2px solid #1B84FF !important;
  transition: color .2s ease;
}

.table-data-cell{
    color:#78829D !important;
    font-weight: 600;
}